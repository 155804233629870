import { CostLevelsTableContext } from './cost-levels-table-context';
import { DayRate } from 'core/api/contracts/contract.types';

import { useCallback, useMemo } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router-dom';

import ActionRenderer from 'components/action-renderer/ActionRenderer';
import AddRowButton from './add-row-button';
import CostLevelsCrudForm from './cost-levels-crud-form';
import DataTableContainer from 'components/data-table/data-table-container';
import ModalContainer from 'components/modal/modal-container';
import SubNavPageHeader from 'components/page-headers/admin-page-header';

interface CostLevelsTableProps {
    data: DayRate[] | undefined;
    error?: number;
    loading: boolean;
    refetch?;
}

const CostLevelsTable = ({ data, refetch }: CostLevelsTableProps) => {
    const { push } = useModals();
    const goTo = useNavigate();

    const columns = [
        {
            Header: 'Job title',
            accessor: 'name',
            cellEditor: 'inputEditor',
            id: 'name'
        },
        {
            Header: 'Day rate',
            accessor: 'cost',
            cellEditor: 'inputEditor',
            id: 'cost'
        },
        {
            Cell: (props) => {
                return (
                    <ActionRenderer
                        context={tableContext}
                        formComponent={CostLevelsCrudForm}
                        {...props}
                    />
                );
            },
            Header: 'Actions'
        }
    ];

    const tableContext = useMemo<CostLevelsTableContext>(
        () => ({
            goTo,
            push
        }),
        [goTo, push]
    );

    const addNewCostLevel = useCallback(() => {
        push(ModalContainer, {
            content: CostLevelsCrudForm,
            crudAction: 'create',
            data: null,
            title: 'Add new cost level'
        });
    }, [push]);

    return (
        <div>
            <SubNavPageHeader pageTitle="Cost Levels">
                <AddRowButton
                    disabled={false}
                    addNewRow={addNewCostLevel}
                ></AddRowButton>
            </SubNavPageHeader>

            <DataTableContainer
                actionButton={AddRowButton}
                context={tableContext}
                columns={columns}
                data={data ? data : []}
            />
        </div>
    );
};

export default CostLevelsTable;
export type { CostLevelsTableProps };
