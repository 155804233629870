/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    ActivityGroup,
    Allocation,
    AllocationWithCost,
    Contract,
    DayRate,
    Product,
    UserBase,
    Uuid
} from './contract.types';
import { ContentType, HttpClient, RequestParams } from './http-client.contract';

export class CoreController<
    SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags core
     * @name GetAllocations
     * @summary Get all allocations
     * @request GET:/core/allocations
     * @secure
     */
    getAllocations = (
        query?: {
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
            /** List of user UUIDs to be used to filter the result set */
            users?: string[];
            /**
             * Limit the result set to resources of the given week
             * @format YYYY-WW
             * @example "2022-45"
             */
            week?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<AllocationWithCost[]>({
            path: `/core/allocations`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name AddAllocation
     * @summary Create an allocation
     * @request POST:/core/allocations
     * @secure
     */
    addAllocation = (data: AllocationWithCost, params: RequestParams = {}) =>
        this.request<AllocationWithCost>({
            path: `/core/allocations`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name UpdateAllocation
     * @summary Update an existing Allocation
     * @request PUT:/core/allocations/{model_uuid}
     * @secure
     */
    updateAllocation = (
        modelUuid: Uuid,
        data: Allocation,
        params: RequestParams = {}
    ) =>
        this.request<any>({
            path: `/core/allocations/${modelUuid}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name DeleteAllocation
     * @summary Delete an existing Allocation
     * @request DELETE:/core/allocations/{model_uuid}
     * @secure
     */
    deleteAllocation = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<any>({
            path: `/core/allocations/${modelUuid}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name GetContracts
     * @summary Get all contracts
     * @request GET:/core/contracts
     * @secure
     */
    getContracts = (
        query?: {
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<Contract[]>({
            path: `/core/contracts`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name CreateContract
     * @summary Create a contract
     * @request POST:/core/contracts
     * @secure
     */
    createContract = (data: Contract, params: RequestParams = {}) =>
        this.request<Contract>({
            path: `/core/contracts`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name UpdateContract
     * @summary Update an existing Contract
     * @request PUT:/core/contracts/{model_uuid}
     * @secure
     */
    updateContract = (
        modelUuid: Uuid,
        data: Contract,
        params: RequestParams = {}
    ) =>
        this.request<any>({
            path: `/core/contracts/${modelUuid}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name DeleteContract
     * @summary Delete an existing Contract
     * @request DELETE:/core/contracts/{model_uuid}
     * @secure
     */
    deleteContract = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<any>({
            path: `/core/contracts/${modelUuid}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name GetDayRates
     * @summary Get all Day Rates
     * @request GET:/core/day-rates
     * @secure
     */
    getDayRates = (
        query?: {
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<DayRate[]>({
            path: `/core/day-rates`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name CreateDayRate
     * @summary Create a Day Rate
     * @request POST:/core/day-rates
     * @secure
     */
    createDayRate = (data: DayRate, params: RequestParams = {}) =>
        this.request<DayRate>({
            path: `/core/day-rates`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name UpdateDayRate
     * @summary Update an existing Day Rate
     * @request PUT:/core/day-rates/{model_uuid}
     * @secure
     */
    updateDayRate = (
        modelUuid: Uuid,
        data: DayRate,
        params: RequestParams = {}
    ) =>
        this.request<any>({
            path: `/core/day-rates/${modelUuid}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name DeleteDayRate
     * @summary Delete an existing Day Rate
     * @request DELETE:/core/day-rates/{model_uuid}
     * @secure
     */
    deleteDayRate = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<any>({
            path: `/core/day-rates/${modelUuid}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name GetProducts
     * @summary Get all products
     * @request GET:/core/products
     * @secure
     */
    getProducts = (
        query?: {
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
            /**
             * If set, only products of this type will be included
             * @example "ncc-software"
             */
            product_type?:
                | 'ncc-software'
                | 'third-party-platform'
                | 'gts-services'
                | 'finance';
        },
        params: RequestParams = {}
    ) =>
        this.request<Product[]>({
            path: `/core/products`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name CreateProduct
     * @summary Create a Product
     * @request POST:/core/products
     * @secure
     */
    createProduct = (data: Product, params: RequestParams = {}) =>
        this.request<Product>({
            path: `/core/products`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name MergeProduct
     * @summary Merge Products
     * @request POST:/core/products/merge
     * @secure
     */
    mergeProduct = (
        data: {
            product?: Product;
            /** UUIDs of the products to be merged together */
            product_uuids?: Uuid[];
        },
        params: RequestParams = {}
    ) =>
        this.request<Product>({
            path: `/core/products/merge`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name UpdateProduct
     * @summary Update an existing Product
     * @request PUT:/core/products/{model_uuid}
     * @secure
     */
    updateProduct = (
        modelUuid: Uuid,
        data: Product,
        params: RequestParams = {}
    ) =>
        this.request<any>({
            path: `/core/products/${modelUuid}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name DeleteProduct
     * @summary Delete an existing Product
     * @request DELETE:/core/products/{model_uuid}
     * @secure
     */
    deleteProduct = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<any>({
            path: `/core/products/${modelUuid}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name GetUsers
     * @summary Get all users
     * @request GET:/core/users
     * @secure
     */
    getUsers = (
        query?: {
            /**
             * Last evaluated key of the current page used to get the next page - Base 64 Encoded.
             * @format paginationKey
             */
            cursor?: string;
            /**
             * Number of results to return (up to)
             * @min 1
             * @max 50
             * @default 10
             */
            limit?: number;
        },
        params: RequestParams = {}
    ) =>
        this.request<UserBase[]>({
            path: `/core/users`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name GetUser
     * @summary Get single users
     * @request GET:/core/users/{model_uuid}
     * @secure
     */
    getUser = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<UserBase>({
            path: `/core/users/${modelUuid}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name UpdateUser
     * @summary Update an existing User
     * @request PUT:/core/users/{model_uuid}
     * @secure
     */
    updateUser = (
        modelUuid: Uuid,
        data: UserBase,
        params: RequestParams = {}
    ) =>
        this.request<void>({
            path: `/core/users/${modelUuid}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name GetActivityGroups
     * @summary Get the list of available activity groups
     * @request GET:/core/activity-groups
     * @secure
     */
    getActivityGroups = (
        query?: {
            /** If given, only groups whose name contains this string will be returned (case insensitive) */
            name?: string;
        },
        params: RequestParams = {}
    ) =>
        this.request<ActivityGroup[]>({
            path: `/core/activity-groups`,
            method: 'GET',
            query: query,
            secure: true,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name PostActivityGroup
     * @summary Create a new activity group
     * @request POST:/core/activity-groups
     * @secure
     */
    postActivityGroup = (data: ActivityGroup, params: RequestParams = {}) =>
        this.request<ActivityGroup>({
            path: `/core/activity-groups`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name PutActivityGroup
     * @summary Update an activity group and its list of activities
     * @request PUT:/core/activity-groups/{model_uuid}
     * @secure
     */
    putActivityGroup = (
        modelUuid: Uuid,
        data: ActivityGroup,
        params: RequestParams = {}
    ) =>
        this.request<void>({
            path: `/core/activity-groups/${modelUuid}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            ...params
        });
    /**
     * No description
     *
     * @tags core
     * @name DeleteActivityGroup
     * @summary Delete an activity group
     * @request DELETE:/core/activity-groups/{model_uuid}
     * @secure
     */
    deleteActivityGroup = (modelUuid: Uuid, params: RequestParams = {}) =>
        this.request<void>({
            path: `/core/activity-groups/${modelUuid}`,
            method: 'DELETE',
            secure: true,
            ...params
        });
}
