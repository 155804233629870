import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

function ActivityActionRenderer(rowData) {
    return (
        <div>
            {rowData.is_activity ? (
                <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="text-utilisation-error"
                />
            ) : (
                <FontAwesomeIcon
                    icon={faCircleCheck}
                    className="text-utilisation-success"
                />
            )}
        </div>
    );
}

export default ActivityActionRenderer;
