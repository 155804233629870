import * as yup from 'yup';
import CostComponent from './form-field-controllers/cost-component';
import CrudForm from 'components/form/components/crud-form';
import NameComponent from 'components/form/components/name-component';
import useDayRateCreate from 'core/api/hooks/cost-levels/use-post-new-day-rate';
import useDeleteCostLevels from 'core/api/hooks/cost-levels/use-delete-cost-levels';
import useUpdateCostLevel from 'core/api/hooks/cost-levels/use-update-cost-level';

const schema = yup.object().shape({
    cost: yup.number().min(2).required('Cost is required'),
    name: yup.string().required('Name is required')
});

const CostLevelsCrudForm = ({ crudAction, data }) => {
    const formComponents = [
        <NameComponent
            key="name"
            readOnly={crudAction === 'delete'}
            type="text"
            placeholder="Please enter a name..."
        />,
        <CostComponent
            key="cost"
            readOnly={crudAction === 'delete'}
            type="number"
            placeholder="Please enter a cost..."
        />
    ];

    const apiHooks = {
        create: useDayRateCreate,
        delete: useDeleteCostLevels,
        update: useUpdateCostLevel
    };

    const getSuccessMessage = (action) => {
        switch (action) {
            case 'create':
                return 'Your Cost Level was created successfully';
            case 'update':
                return 'Your Cost Level was updated successfully';
            case 'delete':
                return 'Your Cost Level was deleted successfully';
            default:
                return '';
        }
    };

    const onSubmitTransform = (formData) => ({ ...formData });

    return (
        <CrudForm
            crudAction={crudAction}
            data={data}
            schema={schema}
            formComponents={formComponents}
            apiHooks={apiHooks}
            getSuccessMessage={getSuccessMessage}
            onSubmitTransform={onSubmitTransform}
        />
    );
};

export default CostLevelsCrudForm;
